import React from "react";

const NotFound = ({contract}) => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>not found 😕</h1>
      </header>
    </div>
  )
};

export default NotFound;
